/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useRef, useState } from "react";
import { ParentLayout } from "../../../layout/parentLayout";
import { useNavigate, useSearchParams } from "react-router-dom";
import SearchFilter from "../common/searchFilter";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import StarIcon from "@mui/icons-material/Star";
import { useFilters } from "../../../context/filterContext";
import {
  useLazyGetFilteredTutorQuery,
} from "../../../service/tutorApi";
import { Booking, TutorDetails } from "../../../types/General";
import { Box, Button, CircularProgress, Drawer, Grid, Skeleton, Typography } from "@mui/material";
import { GoogleMap, InfoWindowF, MarkerF } from "@react-google-maps/api";
import { GOOGLE_API_KEY } from "../../../constants/url";
import { wishListApi } from "../../../service/wishListApi";
import { ParentDashBoardApi } from "../../../service/parentDashboard";
import { showToast } from "../../../constants/toast";
import dayjs from "dayjs";
import LocationDrawer from "../common/locationDrawer";
import Pagination from "../../../constants/Pagination";

type filtersType = {
  experience: string;
  teachingStyle: string;
  verification: string;
  lang: string;
  gender: string;
  time: string;
  curriculam: string;
  rating: string;
  grade: string;
};

export default function ParentSearchResult() {
  const navigate = useNavigate(); // hook for the navigation
  let timeout: NodeJS.Timeout; // Timeout for debouncing fetch requests
  // states
  const [page, setPage] = useState<number>(1);
  const [list, setList] = useState<TutorDetails[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false); // isLoading state
  const { filters, value, time, selectedSubjects, flag } = useFilters(); // context for the filter state
  const viewRef = useRef<HTMLDivElement | null>(null);
  const [showSearchResult, setShowSearchResult] = useState<boolean>(false)
  const [open, setOpen] = React.useState(false); // state to handle open for the track location
  // API Hooks
  const [selectedTutor, setSelectedTutor] = useState<any>(null);
  const [getData] = useLazyGetFilteredTutorQuery();
  const [getTutor] = ParentDashBoardApi.useLazyGetPopularTutorQuery(); // api hook for the popular and recommended tutor
  const [getBooking] = ParentDashBoardApi.useLazyGetCurrentBookingQuery(); // api hook for the current booking and previously booked tutor
  const [isLoadingOne, setLoadingOne] = useState<boolean>(false); // isLoading state
  const [popularTutor, setPopularTutor] = useState<TutorDetails[]>([]); // state to store the data of the popular tutor
  const [recommended, setRecommended] = useState<TutorDetails[]>([]);
  const [addWishlist] = wishListApi.useAddWishListMutation();
  const [totalCount, setTotalCount] = useState<number>()
  const [prevTutor, setPrevTutor] = useState<TutorDetails[]>([]); // state to store the data of previously booked tutor
  const [currentBooking, setCurrentBooking] = useState<Booking>(); // state to store the data of current booking
  const [parentAdd, setParentAdd] = useState<any>();
  let totalPages = Math.ceil((totalCount || 10) / 18); // calculating the total number of pages
  const toggleDrawer = (newOpen: boolean) => () => {
    setOpen(newOpen);
  };

  // function to fetch the data

  const fetchData = async (newPage?:number) => {
    try {
      const subjectsFilter = selectedSubjects?.map((item) => ({ name: item }));

      let body = {
        ...(filters?.experience
          ? { totalTeachingExperience: filters.experience }
          : null),
        ...(filters?.curriculam ? [{ curriculum: filters?.curriculam }] : null),
        ...(filters?.teachingStyle
          ? [{ teachingStyle: filters?.teachingStyle }]
          : null),
        ...(filters?.verification
          ? {
            documentVerification:
              Number(filters?.verification) === 1 ? true : false,
          }
          : null),
        ...(filters?.lang ? { teachingLanguage: Number(filters?.lang) } : null),
        ...(filters?.gender ? { gender: filters?.gender } : null),
        ...(filters?.rating ? { avgRating: filters?.rating } : null),
        ...(value[0] > 5 ? { startPrice: value[0] } : null),
        ...(value[1] > 1000 ? { endPrice: value[1] } : null),
        ...(time?.startTime ? { startTime: time?.startTime } : null),
        ...(time?.endTime ? { endTime: time?.endTime } : null),
        ...(filters?.grade ? [{ classes: filters?.grade }] : null),
        ...(selectedSubjects?.length > 0 ? { subjects: subjectsFilter } : null),
        ...(filters?.latitude ? { latitude: filters.latitude } : null),
        ...(filters?.longitude ? { longitude: filters.longitude } : null),

      };
      console.log(body, "bodyyyy");
      const limit = 18;
      const res = await getData({
        page:newPage||page,
        body,
        limit
      }).unwrap();
      if (res?.statusCode === 200) {

        setLoadingOne(false);
        const newTutors = res?.data?.tutor || [];
        setTotalCount(res?.data?.totalTutor)
        // if (page === 1) {
          setList(newTutors);
          // Set initial listing data
        // } else {
        //   setList((prev) => [...prev, ...newTutors]); // Append new listings
        // }
        // if (newTutors.length === 0) {
        //   setHasMore(false); // No more data to load
        // }
        setShowSearchResult(true)
      }
    } catch (error) {
      setLoadingOne(false);
      console.log(error);
    } finally {
      setLoadingOne(false);
      setIsLoading(false);
    }
  };


  const fetchTutor = async () => {
    try {
      const res = await getTutor({ limit: 4 }).unwrap();
      if (res?.statusCode === 200) {
        setPopularTutor(res?.data?.tutor?.slice(0, 6));
        setRecommended(res?.data?.recomended?.slice(0, 6));
        setParentAdd(res?.data?.parentAddress)
        setIsLoading(false);
        setLoadingOne(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
      setLoadingOne(false);
    }
  };

  // method for the api call to fetch Bookings of the user
  const fetchBooking = async () => {
    try {
      const res = await getBooking({}).unwrap();
      if (res?.statusCode === 200) {
        // setData(res?.data)
        setPrevTutor(res?.data?.tutor);
        setCurrentBooking(res?.data?.booking);
        setLoadingOne(false);
      }
    } catch (error) {
      console.log(error);
      setLoadingOne(false);
    }
  };
  // onChange handler for the page
  const onPageChange = (newPage: number) => {
    setPage(newPage);
    if(showSearchResult){
       fetchData(newPage);
       const middleOfScreen = window.innerHeight / 2;
       setTimeout(() => {
        window.scroll(0, middleOfScreen);
    }, 0);
      }
  };

  const handleWishList = async (item: TutorDetails) => {
    console.log(item, "wish");
    try {
      let body = {
        tutorId: item?._id,
      };

      const res = await addWishlist({ body }).unwrap();
      if (res?.statusCode === 200) {
        if (item?.isFav) {
          showToast("Tutor removed to wishlist");
        } else {
          showToast("Tutor added to wishlist");
        }
        fetchBooking()
        fetchTutor()
      }
    } catch (error: any) {
      console.log(error);
    }
  };


  useEffect(() => {
    setIsLoading(true);
    setLoadingOne(true);
    timeout = setTimeout(() => {
      fetchTutor();
      fetchBooking();
    }, 1000);
    return () => clearTimeout(timeout);
  }, []);


 

  // Intersection Observer to trigger fetching more data when scrolling
  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     (value) => {
  //       if (value[0].isIntersecting) {
  //         if (last) {
  //           observer.unobserve(last);
  //           setPage((page) => page + 1); // Increment page to load more data
  //         }
  //       }
  //     },
  //     { threshold: 0.5 }
  //   );

  //   const last = document.querySelector(".tutor_item:last-child"); // Get last property box element
  //   if (last) {
  //     observer.observe(last); // Observe last property box for visibility
  //   }

  //   return () => {
  //     if (last) {
  //       observer.unobserve(last); // Clean up observer
  //     }
  //     observer.disconnect(); // Disconnect observer
  //   };
  // }, [list, hasMore]);

  // Effect to fetch data based on filters
  // useEffect(() => {
  //   if (!hasMore) return;
  //   setIsLoading(true);
  //   timeout = setTimeout(() => {
  //   fetchData();
  //   }, 2000);
  //   return () => clearTimeout(timeout);
  // }, [page, flag]);

  let center;

  if (filters?.latitude && filters?.longitude) {
    center = {
      lat: parseFloat(filters.latitude) || 0,
      lng: parseFloat(filters.longitude) || 0,
    };
  } else if (selectedTutor) {
    center = {
      lat: selectedTutor.latitude || 0,
      lng: selectedTutor.longitude || 0,
    };
  } else {
    center = {
      lat: parentAdd?.latitude || 0,
      lng: parentAdd?.longitude || 0,
    };
  }


  console.log(parentAdd, "parentAdd");


  useEffect(() => {
    if (viewRef?.current) {
      viewRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [list]);

  console.log(filters, "filters--->>>");

  console.log(value, "value--->filters");
  console.log(time, "time---->> filters");
  console.log(selectedSubjects, "selectedSubjects-->>filters");

  useEffect(() => {
    if (filters?.experience === "" && filters?.curriculam === "" && filters?.teachingStyle === "" && filters?.verification === "" && filters?.lang === ""
      && filters?.gender === "" && filters?.rating === "" && value[0] === 0 && value[1] === 1000 && filters?.time === "" && time?.startTime == "" &&
      time?.endTime === "" && filters?.grade === "" && selectedSubjects?.length === 0 && filters?.longitude === "" && filters?.latitude === ""
    ) {
      setShowSearchResult(false)
    }
  }, [filters?.experience, filters?.curriculam, filters?.teachingStyle, filters?.verification, filters?.lang, filters?.gender,
  filters?.rating, value, time?.startTime, time?.endTime, filters?.grade, selectedSubjects, selectedSubjects, filters?.longitude, filters?.latitude
  ])
  console.log(list, "list");

  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uh_spc pSearchResult_sc">
            <div className="conta_iner v2">
              <SearchFilter setPage={setPage} setHasMore={setHasMore} setShowSearchResult={setShowSearchResult} fetchData={fetchData} />
              <div className="searchResult_box" ref={viewRef}>
                {showSearchResult ? <h2>Search Result</h2> : ""}
                <div className="gap_p">

                  {showSearchResult ? (
                    <div className="lt_s">
                      <div className="tutor_list gap_m">
                        {isLoading && list?.length === 0
                          ? Array.from(new Array(12)).map((item, index) => (
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              width={210}
                              height={220}
                              key={index}
                              sx={{
                                bgcolor: "#FCFDFFEF",
                                borderRadius: "8px",
                              }}
                            />
                          ))
                          : list?.map((item, index) => (
                            <div className="tutor_item" key={item?._id}>
                              <figure>
                                <img
                                  src={
                                    item?.image || `/static/images/card1.png`
                                  }
                                  alt="Image"
                                />
                                <span className="t_rating">
                                  <StarIcon />{" "}
                                  {item?.avgRating?.toFixed(1) || "0"}
                                </span>
                                <span className="t_fav">
                                  {item?.isFav ? (
                                    <FavoriteIcon />
                                  ) : (
                                    <FavoriteBorderIcon />
                                  )}
                                </span>
                                {item?.documentVerification ? (
                                  <span className="t_verify">
                                    <img
                                      src="/static/images/verified.png" alt="img"
                                    />
                                  </span>
                                ) : ("")}

                              </figure>
                              <div
                                className="tutor_info"
                                onClick={() =>
                                  navigate(`/parent/tutor-detail/${item?._id}`)
                                }
                              >
                                <h2>{item?.name || ""}</h2>
                                <p>
                                  {item?.classCount > 99
                                    ? "99+ classes"
                                    : `${item?.classCount || "0"} ${item?.classCount > 1 ? "classes" : "class"}` ||
                                    ""}
                                </p>
                                <p>{item?.subjects?.join(" , ") || ""}</p>
                                <ins>${item?.price || ""}/hour</ins>
                              </div>
                            </div>

                          ))}
                        {list?.length === 0 ?
                          <div style={{ textAlign: "center", fontSize: "30px" }}>No Tutors found</div> : ""
                        }
                        {list?.length ? <Pagination
                          module={list}
                          page={page}
                          onPageChange={onPageChange}
                          totalPages={totalPages}
                        /> : ""}
                        {/* {isLoading && list.length > 0 && (
                          <div className="bottom-loader">
                            <CircularProgress color="success" />
                          </div>
                        )}
                        {!hasMore && !isLoading && (
                          <div className="bottom-loader">
                            <Typography variant="button">
                              No more tutors found
                            </Typography>
                          </div>
                        )} */}
                      </div>
                    </div>
                  ) : (

                    <div className="lt_s">
                      {currentBooking && (
                        <div className="dash_title">
                          <h2>Today’s Booking</h2>
                          <Box
                            component="a"
                            onClick={() => navigate("/parent/my-bookings")}
                          >
                            View more <ArrowForwardIosIcon />
                          </Box>
                        </div>
                      )}

                      {isLoadingOne ? (
                        <Box
                          sx={{
                            padding: 2,
                            borderRadius: 2,
                            backgroundColor: "#FCFDFFEF",
                            marginTop: 5,
                          }}
                        >
                          <Grid container spacing={2} alignItems="center">
                            {/* Avatar Placeholder */}
                            <Grid item>
                              <Skeleton
                                variant="rounded"
                                width={150}
                                height={150}
                              />
                            </Grid>

                            {/* Main Content */}
                            <Grid item xs>
                              <Box
                                sx={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: "center",
                                }}
                              >
                                <Skeleton
                                  variant="text"
                                  width="40%"
                                  height={28}
                                />{" "}
                                {/* Name */}
                                <Skeleton
                                  variant="rectangular"
                                  width={120}
                                  height={32}
                                  sx={{ borderRadius: 1 }}
                                />{" "}
                                {/* Button */}
                              </Box>
                              <Skeleton
                                variant="rectangular"
                                width={160}
                                height={20}
                                sx={{ borderRadius: 1, marginTop: 1 }}
                              />{" "}
                              {/* Booking Code */}
                              <Grid container spacing={2} sx={{ marginTop: 2 }}>
                                <Grid item>
                                  <Skeleton
                                    variant="text"
                                    width={120}
                                    height={20}
                                  />{" "}
                                  {/* Date & Time */}
                                </Grid>
                                <Grid item>
                                  <Skeleton
                                    variant="text"
                                    width={60}
                                    height={20}
                                  />{" "}
                                  {/* Subject */}
                                </Grid>
                                <Grid item>
                                  <Skeleton
                                    variant="text"
                                    width={60}
                                    height={20}
                                  />{" "}
                                  {/* Price */}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      ) : (
                        currentBooking &&
                        (
                          <div className="booking_box">
                            <figure>
                              <img
                                src={
                                  currentBooking?.tutors?.image ||
                                  `/static/images/card3.png`
                                }
                                alt="Image"
                              />
                            </figure>
                            <div className="info">
                              <div className="flex">
                                <div className="lt">
                                  <h3>{currentBooking?.tutors?.name || ""}</h3>
                                  <span className="code">
                                    Booking Code -{" "}
                                    <strong>
                                      {currentBooking?.bookingdetails[0]?.otp
                                        ?.otp || ""}
                                    </strong>
                                  </span>
                                </div>
                                <div className="rt">
                                  <Button onClick={toggleDrawer(true)}>
                                    <img
                                      src={`/static/images/map_icon.svg`}
                                      alt="Icon"
                                    />
                                    Track Location
                                  </Button>
                                </div>
                              </div>
                              <ul>
                                <li>
                                  <span>Date & Time</span>
                                  <strong>
                                    {dayjs(
                                      currentBooking?.bookingdetails[0]?.startTime
                                    )
                                      .locale("D MMMM, YY [at] HH:mm")
                                      .format("D MMMM,YYYY, hh:mm a")}
                                  </strong>
                                </li>
                                <li>
                                  <span>Subject</span>
                                  <strong>
                                    {currentBooking?.subjects[0]?.name || ""}
                                  </strong>
                                </li>
                                <li>
                                  <span>Price</span>
                                  <strong>
                                    $
                                    {currentBooking?.teachingdetails?.price || ""}
                                    /Hour
                                  </strong>
                                </li>
                              </ul>
                            </div>
                          </div>
                        )
                      )}
                      {
                        prevTutor?.length > 0 &&
                        (
                          <div className="dash_title">
                            <h2>Tutors You Booked Previously</h2>
                            {/* <Box
                          component="a"
                          onClick={() => navigate("/parent/booked-tutor")}
                        >
                          View more <ArrowForwardIosIcon />
                        </Box> */}
                          </div>
                        )}
                      <div className="tutor_list gap_m">
                        {
                          isLoadingOne
                            ? Array.from(new Array(3)).map((item, index) => (
                              <Skeleton
                                variant="rounded"
                                animation="wave"
                                width={210}
                                height={220}
                                key={index}
                                sx={{
                                  bgcolor: "#FCFDFFEF",
                                  borderRadius: "8px",
                                }}
                              />
                            ))
                            :
                            prevTutor?.map((item, index) => (
                              <div className="tutor_item" key={item?._id}>
                                <figure>
                                  <img
                                    src={item?.image || "static/images/card1.png"}
                                    alt="tutor image"
                                  />

                                  <span className="t_rating">
                                    <StarIcon />{" "}
                                    {item?.avgRating?.toFixed(1) || "0"}
                                  </span>
                                  {item?.documentVerification ? (
                                    <span className="t_verify">
                                      <img
                                        src="/static/images/verified.png" alt="img"
                                      />
                                    </span>
                                  ) : ("")}

                                  <span className="t_fav" onClick={() => {
                                    handleWishList(item)
                                  }}>


                                    {item?.isFav ? (
                                      <FavoriteIcon />
                                    ) : (
                                      <FavoriteBorderIcon />
                                    )}
                                  </span>
                                  {item?.documentVerification ? (
                                    <span className="t_verify">
                                      <img
                                        src="/static/images/verified.png" alt="img"
                                      />
                                    </span>
                                  ) : ("")
                                  }

                                </figure>
                                <div
                                  className="tutor_info"
                                  onClick={() =>
                                    navigate(`/parent/tutor-detail/${item?._id}`)
                                  }
                                >
                                  <h2>{item?.name || ""}</h2>
                                  <p>
                                    {item?.classCount > 99
                                      ? "99+ classes"
                                      : `${item?.classCount} ${item?.classCount > 1 ? "classes" : "class"}` ||
                                      ""}
                                  </p>
                                  <p>{item?.subjects && item?.subjects?.join(",") || ""}</p>
                                  <ins>${item?.price || ""}/hour</ins>
                                </div>
                              </div>
                            ))}
                      </div>

                      <div className="dash_title">
                        <h2>Popular Tutors</h2>
                        <Box
                          component="a"
                          onClick={() => navigate("/parent/popular-tutor")}
                        >
                          View more <ArrowForwardIosIcon />
                        </Box>
                      </div>
                      <div className="tutor_list gap_m">
                        {isLoading
                          ? Array.from(new Array(3)).map((item, index) => (
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              width={210}
                              height={220}
                              key={index}
                              sx={{
                                bgcolor: "#FCFDFFEF",
                                borderRadius: "8px",
                              }}
                            />
                          ))
                          : popularTutor?.map((item, index) => (
                            <div className="tutor_item" key={item?._id}>
                              <figure>
                                <img
                                  src={item?.image || "/static/images/card1.png"}
                                  alt="tutor image"
                                />
                                <span className="t_rating">
                                  <StarIcon />{" "}
                                  {item?.avgRating?.toFixed(1) || "0"}
                                </span>
                                <span className="t_fav" onClick={() => {
                                  handleWishList(item)
                                }}>
                                  {item?.isFav ? (
                                    <FavoriteIcon />
                                  ) : (
                                    <FavoriteBorderIcon />
                                  )}
                                </span>
                                {item?.documentVerification ? (
                                  <span className="t_verify">
                                    <img
                                      src="/static/images/verified.png" alt="img"
                                    />
                                  </span>
                                ) : ("")}
                              </figure>
                              <div
                                className="tutor_info"
                                onClick={() =>
                                  navigate(`/parent/tutor-detail/${item?._id}`)
                                }
                              >
                                <h2>{item?.name || ""}</h2>
                                <p>
                                  {item?.classCount > 99
                                    ? "99+ classes"
                                    : `${item?.classCount ? item?.classCount : ""} ${item?.classCount > 1 ? "classes" : "class"}` ||
                                    ""}
                                </p>
                                <p>{item?.subjects && item?.subjects?.join(",") || ""}</p>
                                <ins>${item?.price || ""}/hour</ins>
                              </div>
                            </div>
                          ))}
                      </div>

                      <div className="dash_title">
                        <h2>Recomended Tutors</h2>
                        <Box
                          component="a"
                          onClick={() => navigate("/parent/recomended-tutor")}
                        >
                          View more <ArrowForwardIosIcon />
                        </Box>
                      </div>
                      <div className="tutor_list gap_m">
                        {isLoading
                          ? Array.from(new Array(3)).map((item, index) => (
                            <Skeleton
                              variant="rounded"
                              animation="wave"
                              width={210}
                              height={220}
                              key={index}
                              sx={{
                                bgcolor: "#FCFDFFEF",
                                borderRadius: "8px",
                              }}
                            />
                          ))
                          : recommended?.map((item, index) => (
                            <div className="tutor_item" key={item?._id}>
                              <figure>
                                <img
                                  src={item?.image || "static/images/card1.png"}
                                  alt="tutor image"
                                />
                                <span className="t_rating">
                                  <StarIcon />{" "}
                                  {item?.avgRating?.toFixed(1) || "0"}
                                </span>
                                <span className="t_fav" onClick={() => {
                                  handleWishList(item)
                                }}>
                                  {item?.isFav ? (
                                    <FavoriteIcon />
                                  ) : (
                                    <FavoriteBorderIcon />
                                  )}
                                </span>
                                {item?.documentVerification ? (
                                  <span className="t_verify">
                                    <img
                                      src="/static/images/verified.png" alt="img"
                                    />
                                  </span>
                                ) : ("")}
                              </figure>
                              <div
                                className="tutor_info"
                                onClick={() =>
                                  navigate(`/parent/tutor-detail/${item?._id}`)
                                }
                              >
                                <h2>{item?.name || ""}</h2>
                                <p>
                                  {item?.classCount > 99
                                    ? "99+ classes"
                                    : `${item?.classCount} ${item?.classCount > 1 ? "classes" : "class"}` ||
                                    ""}
                                </p>
                                <p>{item?.subjects && item?.subjects?.join(",") || ""}</p>
                                <ins>${item?.price || ""}/hour</ins>
                              </div>
                            </div>
                          ))}
                      </div>

                    </div>
                  )}



                  {/* map right side */}
                  <div className="rt_s">
                    <div className="map" >
                      <GoogleMap
                        mapContainerClassName="map_container"
                        center={center}
                        zoom={5}
                      >
                        {parentAdd && parentAdd.latitude && parentAdd.longitude && (
                          <MarkerF
                            key="parent-add-marker"
                            position={{
                              lat: parentAdd.latitude,
                              lng: parentAdd.longitude,
                            }}
                            icon={{
                              url: parentAdd?.image || "`/static/images/user.png`", // Custom icon
                              scaledSize: new window.google.maps.Size(40, 40), // Adjust size as needed
                            }}
                            onClick={() => setSelectedTutor(parentAdd)} // Handle click if needed
                          />
                        )}

                        {showSearchResult ? (
                          list?.length ? (
                            list.map((tutor, index) => (
                              <MarkerF
                                key={`popular-tutor-${index}`}
                                position={{
                                  lat: tutor?.latitude,
                                  lng: tutor?.longitude,
                                }}
                                onClick={() => setSelectedTutor(tutor)} // Show info window when marker is clicked
                              />
                            ))
                          ) : null
                        ) : (
                          <>
                            {popularTutor?.slice(0, 6).map((tutor, index) => (
                              <MarkerF
                                key={`popular-tutor-${index}`}
                                position={{
                                  lat: tutor?.latitude,
                                  lng: tutor?.longitude,
                                }}
                                onClick={() => setSelectedTutor(tutor)} // Show info window when marker is clicked
                              />
                            ))}
                            {recommended?.slice(0, 6).map((tutor, index) => (
                              <MarkerF
                                key={`recommended-tutor-${index}`}
                                position={{
                                  lat: tutor?.latitude,
                                  lng: tutor?.longitude,
                                }}
                                onClick={() => setSelectedTutor(tutor)} // Show info window when marker is clicked
                              />
                            ))}
                          </>
                        )}

                        {selectedTutor && (
                          <InfoWindowF
                            position={{
                              lat: selectedTutor?.latitude,
                              lng: selectedTutor?.longitude,
                            }}
                            onCloseClick={() => setSelectedTutor(null)} // Close the info window
                          >
                            <div>
                              <h6>{selectedTutor?.name || ""}</h6>
                              <p>{selectedTutor?.address || ""}</p>
                              <img
                                src={selectedTutor?.image ? selectedTutor?.image : "`/static/images/user.png`"}
                                alt="img"
                                style={{ width: "100px", height: "100px" }}
                              />
                            </div>
                          </InfoWindowF>
                        )}
                      </GoogleMap>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
        <Drawer
          className="location_aside"
          anchor="right"
          open={open}
          onClose={toggleDrawer(false)}
        >
          <LocationDrawer toggleDrawer={toggleDrawer} />
        </Drawer>
      </ParentLayout >
    </>
  );
}
