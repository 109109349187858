/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { Button, TextField, Select, MenuItem, SelectChangeEvent, InputAdornment, IconButton } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import MonetizationOnOutlinedIcon from '@mui/icons-material/MonetizationOnOutlined';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EducationLevelModal from '../../../../Modals/educationLevelModal';
import CurriculumModal from '../../../../Modals/curriculumModal';
import GradesModal from '../../../../Modals/gradesModal';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { isNumber, isString } from '../../../../utils/validations';
import dayjs, { Dayjs } from "dayjs";
import { useEditTeachingDetailsMutation, useLazyGetTeachingDetailsQuery, usePostTeachingDetailsMutation } from '../../../../service/tutorProfileSetup';
import { showError, showToast, showWarning } from '../../../../constants/toast';
import Loader from '../../../../constants/Loader';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);


type Subjects = { name: string, isDeleted?: boolean; };
type Conversion = { name: string };

const TutorTeachingDetailEdit = () => {
    const navigate = useNavigate();
    const [language, setLanguage] = React.useState('');
    const [otherCurriculam, setOtherCurriculam] = useState<string>("");
    const [experience, setExperience] = useState<string>("");
    const [startTime, setStartTime] = useState<string | null>(null)
    const [endTime, setEndTime] = useState<string | null>(null);
    const [price, setPrice] = useState<string>("");
    const [selectedCurriculam, setSelectedCurriculam] = useState<number[]>([]);
    const [selectedTeaching, setSelectedTeaching] = useState<number[]>([]);
    const [selectedlevel, setSelectedLevels] = useState<Conversion[]>([]);
    const [prevlevel, setPrevLevels] = useState<Conversion[]>([]);
    const [subjectsArray, setSubjectsArray] = useState<Subjects[]>([]);
    const [subject, setSubject] = useState<string>("")
    const [open1, setOpen1] = useState(false);
    const [prevSubjects, setPrevSubjects] = useState<Subjects[]>([]);
    // const [postTeaching] = useEditTeachingDetailsMutation();
    const [postTeaching] = usePostTeachingDetailsMutation();

    const [getTeaching] = useLazyGetTeachingDetailsQuery();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [teachingData, setTeachingData] = useState<any>();
    const [reqError, setReqError] = useState<string>("");

    const validateForm = () => {
        if (experience?.length === 0) {
            setReqError("Please select your experience.");
            return false;
        }
        if (subjectsArray?.length === 0) {
            setReqError("Please add at least one subject.");
            return false;
        }
        if (selectedCurriculam?.length === 0 && !otherCurriculam) {
            setReqError("Please select a curriculum.");
            return false;
        }
        if (selectedlevel?.length === 0) {
            setReqError("Please select at least one level.");
            return false;
        }
        if (selectedTeaching?.length === 0) {
            setReqError("Please select at least one teaching style.");
            return false;
        }
        if (!language) {
            setReqError("Please select a language.");
            return false;
        }
        if (!price) {
            setReqError("Please enter a Price.");
            return false;
        }
        if (!startTime) {
            setReqError("Please select a start time.");
            return false;
        }
        if (!endTime) {
            setReqError("Please select an end time.");
            return false;
        }
        return true;
    };


    const fetchTeaching = async () => {
        setIsLoading(true)
        try {
            const res = await getTeaching({}).unwrap();
            if (res?.statusCode === 200) {
                setTeachingData(res?.data);
                setSelectedLevels(res?.data[0]?.classes)
                setPrevLevels(res?.data[0]?.classes)
                setSelectedCurriculam(res?.data[0]?.curriculum)
                setOtherCurriculam(res?.data[0]?.curriculumOther)
                setSelectedTeaching(res?.data[0]?.teachingStyle)
                setLanguage(res?.data[0]?.teachingLanguage ? res?.data[0]?.teachingLanguage : "")
                setExperience(res?.data[0]?.totalTeachingExperience ? res?.data[0]?.totalTeachingExperience : "")
                setStartTime(res?.data[0]?.startTime)
                setEndTime(res?.data[0]?.endTime)
                setSubjectsArray(res?.data[0]?.subjects)
                setPrevSubjects(res?.data[0]?.subjects)
                setPrice(res?.data[0]?.price)
            }

            setIsLoading(false)
        } catch (error: any) {
            setIsLoading(false)
            showError(error?.data?.message)
        }
    }
    console.log(subjectsArray, "subjets");
    console.log(prevlevel, "prevlevel");


    const fetchPostTeaching = async () => {
        setIsLoading(true)

        const tempClass = prevlevel.map((item) => {
            if (selectedlevel.find((data: any) => data?.name === item?.name)) {
                return { ...item, isDelete: false };
            } else {
                return { ...item, isDelete: true };
            }
        });
        selectedlevel.map((item: any) => {
            if (!prevlevel.find((data: any) => data.name === item.name)) {
                tempClass.push(item);
            }
        });

        const tempSubject = prevSubjects.map((item: any) => {
            if (subjectsArray.find((data: any) => data.name === item.name)) {
                return { ...item, isDelete: false };
            } else {
                return { ...item, isDelete: true };
            }
        });
        subjectsArray.map((item: any) => {
            if (!prevSubjects.find((data: any) => data.name === item.name)) {
                tempSubject.push(item);
            }
        });
        console.log(tempSubject, "tempSubject");
        console.log(tempClass, "tempClass");


        if (startTime === null) {
            showWarning("Please select start time")
        }
        else if (endTime === null) {
            showWarning("Please select end time")
        }

        const body = {
            totalTeachingExperience: Number(experience),
            subject: tempSubject,
            curriculum: selectedCurriculam,
            curriculumOther: otherCurriculam,
            classes: tempClass,
            teachingStyle: selectedTeaching,
            teachingLanguage: Number(language),
            price: Number(price),
            startTime: startTime,
            endTime: endTime,
        }
        console.log(body, "body of teching details");

        try {
            // const res = await postTeaching({body:body,id:teachingData?.[0]?._id}).unwrap();
            const res = await postTeaching(body).unwrap();
            setIsLoading(false)
            if (res?.statusCode === 200) {
                showToast("Teaching details updated successfully")
                navigate('/tutor/profile')
            }
        } catch (error: any) {
            setIsLoading(false)
            showError(error?.data?.message)
        }
    }


    const handleChangeExp = (event: SelectChangeEvent) => {
        setExperience(event.target.value as string);
    };
    const handleChange = (event: SelectChangeEvent) => {
        setLanguage(event.target.value as string);
    };
    const handleCloseModal1 = () => {
        setOpen1(false);
    };
    const [open2, setOpen2] = useState(false);
    const handleCloseModal2 = () => {
        setOpen2(false);
    };

    const [open3, setOpen3] = useState(false);
    const handleCloseModal3 = () => {
        setOpen3(false);
    };

    // const handleAddSubject = () => {
    //     if (subject !== '') {
    //         setSubjectsArray((prev) => {
    //             return Array.isArray(prev) ? [...prev, { name: subject.trim() }] : [{ name: subject.trim() }]
    //         })
    //         // setSubjectsArray([...subjectsArray,{ name: subject.trim() }]);
    //         setSubject('');
    //     }
    // }

    const handleAddSubject = () => {
        if (subject.trim() !== '') {
            const subjectsToAdd = subject.split(',').map(item => item.trim()).filter(Boolean);

            setSubjectsArray((prev) => {
                return Array.isArray(prev) ? [...prev, ...subjectsToAdd.map(name => ({ name }))] : subjectsToAdd.map(name => ({ name }));
            });

            setSubject('');
        }
    };

    const handleRemoveSubject = (indexToRemove: any) => {
        setSubjectsArray(subjectsArray.filter((_, index) => index !== indexToRemove));
    };


    const handleRemoveCurr = (indexToRemove: any) => {
        setSelectedCurriculam(selectedCurriculam.filter((_, index) => index !== indexToRemove));
    };
    const handleRemoveTeaching = (indexToRemove: any) => {
        setSelectedTeaching(selectedTeaching.filter((_, index) => index !== indexToRemove));
    };
    const handleRemoveLevels = (indexToRemove: any) => {
        setSelectedLevels(selectedlevel.filter((_, index) => index !== indexToRemove));
    };


    const getCurriculumName = (item: number) => {
        switch (item) {
            case 1:
                return 'National Curriculum';
            case 2:
                return 'Cambridge Curriculum';
            case 3:
                return 'IB Curriculum';
            case 4:
                return 'American Curriculum';
            default:
                return '';
        }
    };

    const getTeachingName = (item: number) => {
        switch (item) {
            case 1:
                return 'Visual Learning';
            case 2:
                return 'Auditory Learning';
            case 3:
                return 'Reading and Writing';
            case 4:
                return 'Integrated Approach';
            case 5:
                return 'Other';
            default:
                return '';
        }
    }

    const getLevelName = (item: string) => {
        switch (item) {
            case "0":
                return 'Pre primary(Kg/Foundation)';
            case "1":
                return 'Primary';
            case "2":
                return 'Middle school (O-level)';
            case "3":
                return 'High school (A-level)';
            case "4":
                return 'College';
            case "5":
                return 'Other';
            default:
                return '';
        }
    }

    console.log(otherCurriculam, "other in step");
    console.log(selectedlevel, "selectedlevel");


    const handleTimeChange = (time: Dayjs | null) => {
        if (time) {
            const utcTime = time.utc().format();
            setStartTime(utcTime);
        } else {
            setStartTime(null);
        }
        console.log(startTime, "start time");

    };

    const handleTimeChangeEnd = (time: Dayjs | null) => {
        if (time) {
            const utcTime = time.utc().format();
            setEndTime(utcTime);
        } else {
            setEndTime(null);
        }
    };



    const handleSubmit = () => {
        if (validateForm()) {
            fetchPostTeaching();
        } else {
            showError(reqError);
        }
    };

    useEffect(() => {
        fetchTeaching();
    }, [])

    return (
        <>
            <main className="content editTutor">
                <Loader isLoad={isLoading} />

                <section className="tutor_setup">
                    <div className="conta_iner">
                        <div className="rt_s">
                            <h2>
                                <button className="back_arrow" onClick={() => navigate("/tutor/profile")}>
                                    <img src={`/static/images/back.png`} alt="img" />
                                </button>
                                <strong>Edit Teaching Details</strong>
                            </h2>
                            <form className="form">
                                <div className="gap_p">
                                    <div className="control_group w_100">
                                        <Select
                                            labelId="language-label"
                                            id="language"
                                            value={experience}
                                            onChange={handleChangeExp}
                                            displayEmpty
                                            error={!experience}
                                        >
                                            <MenuItem value="" disabled>Experience</MenuItem>
                                            <MenuItem value="1">1 Year</MenuItem>
                                            <MenuItem value="2">2 Years</MenuItem>
                                            <MenuItem value="3">3 Years</MenuItem>
                                            <MenuItem value="4">4 Years</MenuItem>
                                            <MenuItem value="5">5+ Years</MenuItem>
                                        </Select>
                                        {!experience && <span className="error">Experience is required</span>}
                                    </div>

                                    <div className="control_group w_100">
                                        <div className="addMore">
                                            <TextField
                                                hiddenLabel
                                                fullWidth
                                                placeholder="Add Subjects"
                                                value={subject}
                                                onChange={(e) => {
                                                    const value = e.target.value;

                                                    if (value === " " || value === ".") {
                                                    } else if (typeof value === "string") {
                                                        setSubject(value);
                                                    }
                                                }}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment className="add_icon" position="end">
                                                            <IconButton onClick={handleAddSubject}>
                                                                <AddIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                            {subjectsArray?.length === 0 && <span className="error">At least one subject is required</span>}
                                            {subjectsArray?.length ? (
                                                <ul>
                                                    {subjectsArray.filter(item => item.isDeleted !== true).map((item, index) => (
                                                        <li key={index}>
                                                            {item?.name}{" "} <span onClick={() => handleRemoveSubject(index)}><CloseIcon /></span>
                                                        </li>
                                                    ))}
                                                </ul>
                                            ) : null}

                                        </div>
                                    </div>

                                    <div className="control_group w_100">
                                        <div className="addMore">
                                            <p>Select Curriculum <span onClick={() => setOpen2(true)}><AddIcon /></span></p>
                                            <ul>
                                                {selectedCurriculam?.map((item, index) => (
                                                    <li key={index}>
                                                        {getCurriculumName(item)} <span><CloseIcon onClick={() => handleRemoveCurr(index)} /></span>
                                                    </li>
                                                ))}
                                                {selectedCurriculam?.length === 0 && !otherCurriculam && <span className="error">Curriculum is required</span>}
                                                {otherCurriculam?.length ? (
                                                    <li>
                                                        {otherCurriculam} <span onClick={() => setOtherCurriculam("")}><CloseIcon /></span>
                                                    </li>
                                                ) : ("")}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="control_group w_100">
                                        <div className="addMore">
                                            <p>Select Level <span onClick={() => setOpen3(true)}><AddIcon /></span></p>
                                            {selectedlevel?.length === 0 && <span className="error">At least one level is required</span>}
                                            <ul>
                                                {selectedlevel?.map((item, index) => (
                                                    <li key={index}>
                                                        {getLevelName(item?.name)} <span><CloseIcon onClick={() => handleRemoveLevels(index)} /></span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="control_group w_100">
                                        <div className="addMore">
                                            <p>Select Teaching Style <span onClick={() => setOpen1(true)}><AddIcon /></span></p>
                                            {selectedTeaching?.length === 0 && <span className="error">At least one teaching style is required</span>}
                                            <ul>
                                                {selectedTeaching?.map((item, index) => (
                                                    <li key={index}>
                                                        {getTeachingName(item)} <span><CloseIcon onClick={() => handleRemoveTeaching(index)} /></span>
                                                    </li>
                                                ))}
                                            </ul>
                                        </div>
                                    </div>

                                    <div className="control_group w_50">
                                        <Select
                                            labelId="language-label"
                                            id="language"
                                            value={language}
                                            onChange={handleChange}
                                            displayEmpty
                                            error={!language}
                                        >
                                            <MenuItem value="" disabled>Select Language</MenuItem>
                                            <MenuItem value="1">English</MenuItem>
                                            <MenuItem value="2">Arabic</MenuItem>
                                            <MenuItem value="3">Both</MenuItem>
                                        </Select>
                                        {!language && <span className="error">Language is required</span>}
                                    </div>
                                    <div className="control_group w_50">
                                        <TextField
                                            hiddenLabel
                                            fullWidth
                                            placeholder="Enter price"
                                            value={price}
                                            onChange={(val) => {
                                                const value = val.target.value;
                                                if (/^\d*\.?\d*$/.test(value)) {
                                                    setPrice(value);
                                                }
                                            }}
                                            InputProps={{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <MonetizationOnOutlinedIcon />
                                                    </InputAdornment>
                                                ),
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <button className="verify_btn">per hour</button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        ></TextField>
                                        {!price && <span className="error">Price is required</span>}
                                    </div>
                                    <div className="control_group w_50">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                className="label_hidden"
                                                label="Teaching Start Time"
                                                onChange={handleTimeChange}
                                                value={dayjs(startTime)}

                                            />
                                        </LocalizationProvider>
                                    </div>
                                    <div className="control_group w_50">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <TimePicker
                                                className="label_hidden"
                                                label="Teaching End Time"
                                                onChange={handleTimeChangeEnd}
                                                value={dayjs(endTime)}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                </div>
                                <div className="form_btn">
                                    <Button variant="outlined" color="primary" onClick={() => navigate('/tutor/profile')}>Cancel</Button>
                                    <Button
                                        onClick={handleSubmit}
                                    // onClick={() => navigate('/auth/as-tutor/profile-setup/step3')}
                                    >Continue</Button>
                                </div>
                            </form>
                        </div>
                    </div>
                </section >
            </main >

            <EducationLevelModal
                open={open1}
                onClose={handleCloseModal1}
                setOpen={setOpen1}
                setSelected={setSelectedTeaching}
                selected={selectedTeaching}
            />

            <CurriculumModal
                open={open2}
                onClose={handleCloseModal2}
                setOpen={setOpen2}
                setSelectedCurriculam={setSelectedCurriculam}
                other={otherCurriculam}
                setOther={setOtherCurriculam}
                selectedCurriculam={selectedCurriculam}
            />

            <GradesModal
                open={open3}
                onClose={handleCloseModal3}
                setOpen={setOpen3}
                setSelected={setSelectedLevels}
                selected={selectedlevel}
            />
        </>
    )
}


export default TutorTeachingDetailEdit;