import React, { useEffect, useRef, useState } from "react";
import { ParentLayout } from "../../../layout/parentLayout";
import { useLocation, useNavigate } from "react-router-dom";
import {
  TextField,
  InputAdornment,
  IconButton,
  Button,
  Card,
  Skeleton,
  CardContent,
  Box,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { socket } from "../../../utils/socket";
import {
  useGetChatListQuery,
  useLazyGetChatHistoryQuery,
  useLazyGetChatListQuery,
} from "../../../service/chatApi";
import moment from "moment";
import { chatHistory, ChatItem } from "../../../types/General";
import useAuth from "../../../hooks/useAuth";
import clsx from "clsx";

type detailsProp = {
  bookingId: string;
  connectionId: string;
  name: string;
  image: string;
};

const groupMessagesByDate = (messages: any[]) => {
  return messages.reduce((groups, message) => {
    const messageDate = moment(message.createdAt).format("YYYY-MM-DD"); // Group by date
    if (!groups[messageDate]) {
      groups[messageDate] = [];
    }
    groups[messageDate].push(message);
    return groups;
  }, {});
};
const renderDateMarker = (date: string) => {
  const today = moment().startOf("day");
  const messageDate = moment(date);

  if (messageDate.isSame(today, "day")) {
    return "Today";
  } else if (messageDate.isSame(today.clone().subtract(1, "days"), "day")) {
    return "Yesterday";
  } else {
    return messageDate.format("MMMM D, YYYY");
  }
};
export default function ParentChat() {
  const navigate = useNavigate(); // hook for the navigation
  const location = useLocation(); // using the useLocation hook to get the location and afterwards the data inside the state
  const { state } = location;
  const user = useAuth();

  // STATES
  const [page, setPage] = useState<number>(1);
  const [historyPage, setHistoryPage] = useState<number>(1);
  const [isActive, setIsActive] = useState(false);
  const [input, setInput] = useState<string>(""); // input state to store the message
  const [chat, setChat] = useState<ChatItem[]>([]);
  const [history, setHistory] = useState<chatHistory[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [hasMoreHistory, setHasMoreHistory] = useState<boolean>(true);

  const [details, setDetails] = useState<detailsProp>({
    bookingId: state?.bookingId || "",
    connectionId: state?.connectionId || "",
    name: state?.name || "",
    image: state?.image || "",
  });
  const scrollRef = useRef<any>();
  const [status, setStatus] = useState({
    listLoading: false,
    chatHistoryLoading: false,
  });
  // API HOOKS
  const [getChatList] = useLazyGetChatListQuery();
  const [getChatHistory] = useLazyGetChatHistoryQuery();

  // function to implement data fetching
  const fetchChatList = async () => {
    try {
      setStatus((prev) => ({
        ...prev,
        listLoading: true,
      }));
      const res = await getChatList({ page: page }).unwrap();
      if (res?.statusCode === 200) {
        setHasMore(res?.data?.chat?.length > 0);

        setChat((prev) =>
          page === 1 ? res?.data?.chat : [...prev, ...res?.data?.chat]
        );
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setStatus((prev) => ({
        ...prev,
        listLoading: false,
      }));
    }
  };

  const fetchHistory = async () => {
    try {
      setStatus((prev) => ({
        ...prev,
        chatHistoryLoading: true,
      }));
      const res = await getChatHistory({
        page: historyPage,
        connectionId: details?.connectionId || "",
      }).unwrap();
      if (res?.statusCode === 200) {
        const arr = [...res?.data?.message]?.reverse();
        setHasMoreHistory(res?.data?.message?.length > 0);
        // setHistory(arr);
        setHistory((prev) => (historyPage === 1 ? arr : [...arr, ...prev]));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      setStatus((prev) => ({
        ...prev,
        chatHistoryLoading: false,
      }));
    }
  };

  const handleClick = () => {
    setIsActive(!isActive);
  };

  // room joining
  const joinRoom = () => {
    socket.emit("joinRoom", {
      bookingId: details?.bookingId,
      connectionId: details?.connectionId,
    });
  };

  // sending message
  const sendMessage = async () => {
    if (!input) {
      return;
    }
    let body = {
      bookingId: details?.bookingId,
      connectionId: details?.connectionId,
      sentBy: user?.type,
      message: input,
    };
    socket.emit("send_message_user", body);
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    setInput("");
  };

  useEffect(() => {
    if (details?.bookingId) {
      joinRoom();
    }
  }, [details?.bookingId, details?.connectionId]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (value) => {
        if (value[0].isIntersecting) {
          if (last) {
            observer.unobserve(last);
            setPage((page) => page + 1); // Increment page to load more data
          }
        }
      },
      { threshold: 0.5 }
    );

    const last = document.querySelector(".chat_single:last-child"); // Get last property box element
    if (last) {
      observer.observe(last); // Observe last property box for visibility
    }

    return () => {
      if (last) {
        observer.unobserve(last); // Clean up observer
      }
      observer.disconnect(); // Disconnect observer
    };
  }, [hasMore, chat]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (value) => {
        if (value[0].isIntersecting) {
          if (last) {
            observer.unobserve(last);
            setHistoryPage((page) => page + 1); // Increment page to load more data
          }
        }
      },
      { threshold: 0.5 }
    );

    const last = document.querySelector(".msg_body:first-child"); // Get last property box element
    if (last) {
      observer.observe(last); // Observe last property box for visibility
    }

    return () => {
      if (last) {
        observer.unobserve(last); // Clean up observer
      }
      observer.disconnect(); // Disconnect observer
    };
  }, [hasMoreHistory, history]);

  useEffect(() => {
    if (!hasMore) return;
    fetchChatList();
  }, [page]);

  useEffect(() => {
    if (!socket?.connected) {
      joinRoom();
    }
    socket?.on("connect", () => {
      joinRoom();
    });
    socket?.on("joinRoomOk", (value: any) => {
      console.log(value, "event is here");
    });
    socket?.on("send_message_Ok", (message: any) => {
      console.log(message, "<<<<<<<<<<<<<<<<<<<<,message");
      setHistory((prevMessages) => [...prevMessages, message?.data]);
      if (scrollRef.current) {
        scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
      }
    });

    return () => {
      // Clean up socket listeners
      socket?.off("connect");
      socket?.off("joinRoomOk");
      socket?.off("send_message_Ok");
    };
  }, [socket]);

  useEffect(() => {
    if (!details?.connectionId) return;
    if (!hasMoreHistory) return;
    fetchHistory();
  }, [details?.connectionId, historyPage]);

  useEffect(() => {
    if (scrollRef.current  && historyPage===1 || historyPage===2) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  }, [history,historyPage]);

  const groupMessage = groupMessagesByDate(history);
  console.log(hasMoreHistory,"hasMOre")
  return (
    <>
      <ParentLayout className="role-layout">
        <main className="content">
          <section className="uhb_spc pChat_sc">
            <div className="conta_iner v2">
              <div className="role_head">
                <button
                  className="back_arrow"
                  onClick={() => navigate("/parent/search-result")}
                >
                  <img src={`/static/images/back.png`} alt="Back" />
                </button>
                <h1 className="hd_3">Chat</h1>
              </div>
              <div className="role_body">
                <div className="chat_sc gap_m">
                  <div className={isActive ? "lt_s hide" : "lt_s"}>
                    {/* <div className="search_bar">
                      <div className="control_group">
                        <TextField
                          fullWidth
                          hiddenLabel
                          placeholder="Search messages"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="end">
                                <img
                                  src={"/static/images/search_icon.svg"}
                                  alt="Icon"
                                />
                              </InputAdornment>
                            ),
                          }}
                        ></TextField>
                      </div>
                    </div> */}
                    {status.listLoading &&
                      Array.from(new Array(4)).map((item, index) => (
                        <ChatListSkeletom key={index} />
                      ))}

                    {chat?.length && !status.listLoading ? (
                      chat?.map((item, index) => (
                        <div
                          key={item?._id}
                          className="chat_single"
                          onClick={() => {
                            handleClick();
                            
                            setDetails(() => ({
                              bookingId: item?.bookingId,
                              connectionId: item?._id,
                              name: item?.tutorId?.name,
                              image: item?.tutorId?.image,
                            }));
                            setHasMoreHistory(true)
                            setHistoryPage(1)
                          }}
                        >
                          {/* <span className="count">2</span> */}
                          <figure>
                            <img
                              src={
                                item?.tutorId?.image
                                  ? item?.tutorId?.image
                                  : "/static/images/parent_attachement.png"
                              }
                              alt="User"
                            />
                          </figure>
                          <div className="c_info">
                            <p>
                              <strong>{item?.tutorId?.name || ""}</strong>
                              <span className="time">
                                {moment(item?.createdAt).fromNow()}
                              </span>
                            </p>
                            <p>{item?.message || ""}</p>
                          </div>
                        </div>
                      ))
                    ) : status.listLoading ? null : (
                      <div>
                        <p>No chats found</p>
                      </div>
                    )}
                  </div>
                  <div className={isActive ? "rt_s active" : "rt_s"}>
                    {details?.connectionId ? (
                      <div className="chat_box" ref={scrollRef}>
                        <div className="chat_head">
                          <IconButton
                            className="roundIcon_btn"
                            onClick={handleClick}
                          >
                            <ArrowBackIcon />
                          </IconButton>
                          <figure>
                            <img
                              src={
                                details?.image ||
                                "/static/images/parent_attachement.png"
                              }
                              alt="User"
                            />
                          </figure>
                          <h2>
                            <strong>{details?.name || ""}</strong>
                            {/* <span>Started the Job</span> */}
                          </h2>
                        </div>

                        <div className="chat_body">
                          {Object.keys(groupMessage).map((date) => (
                            <div key={date}>
                              {/* Render the date marker */}
                              <div className="msg_container">
                                <span id="msg_day" className="msg_day">
                                  {renderDateMarker(date)}
                                </span>
                              </div>

                              {groupMessage[date].map(
                                (item: any, index: any) => (
                                  <div
                                    key={index}
                                    className={clsx("single_message", {
                                      recieved: user?.type !== item?.sentBy,
                                      sended: user?.type === item?.sentBy,
                                    })}
                                  >
                                    <div className="msg_body">
                                      <span>
                                        {moment(item.createdAt).format("LT")}
                                      </span>
                                      <p>{item.message}</p>
                                    </div>
                                  </div>
                                )
                              )}
                            </div>
                          ))}
                        </div>

                        <div className="chat_foot">
                          <div className="control_group">
                            <TextField
                              hiddenLabel
                              value={input}
                              placeholder="Type a message..."
                              fullWidth
                              onChange={(e) => setInput(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  sendMessage();
                                }
                              }}
                            />
                            <Button onClick={sendMessage}>
                              <img
                                src={"/static/images/export_icon.svg"}
                                alt="Icon"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="no-chat-container">
                        <figure>
                          <img src="/static/images/user.png" alt="" />
                        </figure>
                        <p>Select user to chat</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </ParentLayout>
    </>
  );
}

const ChatListSkeletom = () => {
  return (
    <Card
      style={{
        display: "flex",
        alignItems: "center",
        padding: "5px",
        marginBottom: "10px",
        maxHeight: "84px",
      }}
    >
      <Skeleton variant="circular" width={40} height={40} />
      <CardContent style={{ flex: 1 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="start"
          flexDirection={"column"}
        >
          <Skeleton variant="text" width="40%" height={30} />
          <Skeleton variant="text" width="60%" height={20} />
        </Box>
      </CardContent>
    </Card>
  );
};
